/* TABLE HEADER STYLES */

.responsive {
  overflow-x: auto;
  width: 100%;
  margin-bottom: 10px;
  /* border-bottom: 1px solid #cfd0d3; */
}

.header {
  display: flex;
  align-items: center;
}

.tableHeadingRow {
  margin-bottom: 20px;
}

.bottomNavDiv {
  position: relative;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  font-family: GTWalsheimProRegular;
  margin-top: 20px;
  align-items: center;
  justify-items: center;
}

.navButtons {
  font-size: 18px;
  font-family: GTWalsheimProRegular;
  align-items: center;
  justify-items: center;
  align-content: center;
  justify-content: center;
  color: #ffffff;
  background-color: #7C596A;
  border-radius: 10px;
  border: #ffffff 0px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.navButtons:hover {
  background-color: #4E253A;
  cursor: pointer;
}

.navButtonsDisable {
  font-size: 18px;
  font-family: GTWalsheimProRegular;
  align-items: center;
  justify-items: center;
  align-content: center;
  justify-content: center;
  color: #ffffff;
  background-color: #7C596A;
  border-radius: 10px;
  border: #ffffff 0px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.navButtonsDisable:hover {
  background-color: #7C596A;
  cursor: pointer;
}

.pageSizeNumberDiv {
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  font-size: 1.0em;
  font-family: GTWalsheimProRegular;
  align-items: center;
  justify-items: center;
  align-content: center;
  justify-content: center;
  font-weight: bold;
}

.pageSizeMenu {
  display: flex !important;
  flex-direction: column !important;
  font-size: 10px;
}

.nameHeader {

  text-align: center;
  align-items: center;
  font-size: 1.0em;
  font-family: GTWalsheimProRegular;
  color: #292f4c;
  margin-bottom: 10px;
  cursor: pointer;
}

.elapsedTimeHeader {

  text-align: center;
  align-items: center;
  font-size: 1.0em;
  font-family: GTWalsheimProRegular;
  cursor: pointer;
}

.locationHeader {

  text-align: center;
  align-items: center;
  font-size: 1.0em;
  font-family: "GTWalsheimProRegular";
  cursor: pointer;
}

.physicianHeader {

  text-align: center;
  align-items: center;
  font-size: 1.0em;
  font-family: "GTWalsheimProRegular";
  cursor: pointer;
}

.dailyMeasurementsHeader {

  text-align: center;
  align-items: center;
  font-size: 1.0em;
  font-family: "GTWalsheimProRegular";
}

.patientIDHeader {

  text-align: center;
  align-items: center;
  font-size: 1.0em;
  font-family: "GTWalsheimProRegular";
  cursor: pointer;
}

.buttonTabHeader {
  width: 50px;
  text-align: center;
  font-size: 1.0em;
}

.iconsStyleSort {
  color: #ffffff;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 5px;
}

/* TABLE HEADER STYLES */

/* SEARCH BAR STYLE */

.searchInput {
  margin-left: 10px;
  color: #353b48;
  border: 0;
  outline: 0;
  background: none;
  width: 100%;
  caret-color: white;
  font-family: "GTWalsheimProRegular";
  font-size: 15px;
  caret-color: #29304c;
}

.searchInput::placeholder {
  /* color: white; */
  color: #353b48;
  font-family: "GTWalsheimProRegular" light;
}

.searchbar:focus {
  background-color: cyan;
}

.searchInput:focus {
  color: #29304c;
  caret-color: #29304c;
}

.searchIcon {
  height: 35px;
  width: 35px;
  float: right;
  /* display: flex; */
  justify-content: center;
  /* align-items: center; */
  border-radius: 50%;
  text-decoration: none;
  color: #4E253A;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 5px;
  margin-left: auto;
}

/* SEARCH BAR STYLE */
.searchBar {
  position: relative;
  width: 100%;
  height: 40px;
  border-radius: 50px;
  font-size: 16px;
  box-shadow: 0px 0px 4px #ccc;
  font-style: italic;
}

.topDiv {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  margin-top: 20px;
}

.wrapper {
  background-color: #FFF8F9;
  display: flex;
  flex-direction: row;
  outline: none;
  width: fit-content;
  border-radius: 20px;
  align-items: center;
  overflow-x: hidden;
  position: absolute;
  /* -ms-transform: translate(-20%, -20%); */
  transform: translate(-50%, 2%);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.title {
  display: flex;
  color: #4E253A;
}

.iconDivStyles svg {
  width: 22px;
  height: 22px;
  padding-top: 2px;
  padding-left: 5px;
}

.profile {
  font-size: 20px;
  font-family: "GTWalsheimProRegular";
}

.closeIcon {
  position: absolute;
  right: 0%;
  top: 0%;
}

.card {
  background-color: #ffff;
  width: 92%;
  border-radius: 10px;
  box-shadow: 2px 2px 15px -4px rgba(0, 0, 0, 0.20);
  margin: 9px 0px 10px 9px;
}

.patientDataWrapper {
  width: auto;
  border-top: 1px solid #F2F2F2;
  border-radius: 10px;
  /* padding: 10px; */
  max-height: 40vh;
  overflow-y: scroll;
}

.patientDataWrapper {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.patientDataWrapper::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.profileField {
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: baseline;
  color: #4E253A;
  padding: 9px 10px;
  border-bottom: 1px solid #F2F2F2;
  justify-content: space-between;
}

.boldText {
  width: 50%;
  font-family: GTWalsheimProRegular;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  padding-left: 3px;
}

.regularText {
  font-family: GTWalsheimProRegular;
  font-size: 14px;
  font-weight: 400;
  color: #7C596A;
}

.boldTextManyLines {
  width: 50%;
  font-family: GTWalsheimProRegular;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  padding-left: 3px;
  padding-right: 22%;
}

.regularTextManyLines {
  font-family: GTWalsheimProRegular;
  font-size: 14px;
  font-weight: 400;
  color: #7C596A;
  display: table-caption;
  word-spacing: 9999rem;
  text-align: left;
}

.filtersDiv {}

.filtersDivText {
  font-family: 'GTWalsheimProRegular' !important;
  font-size: 12px !important;
  padding-bottom: 5px;
  column-gap: 5px;
  color: #4E253A;
  font-weight: bold;
  height: 30px;
  padding-left: 20px;
  width: 80px;
  margin-right: 0px !important;
  padding-top: 5px;
  padding-right: 5px;
}

.filtersDivTextSelected {
  font-family: 'GTWalsheimProRegular' !important;
  font-size: 12px !important;
  column-gap: 5px;
  color: #4E253A;
  font-weight: bold;
  background-color: #FDEAFF;
  padding-left: 20px;
  width: 80px;
  height: 30px;
  margin-right: 0px !important;
  padding-bottom: 5px;
  padding-top: 5px;
  padding-right: 5px;
}

.filtersDivText1 {
  font-family: 'GTWalsheimProRegular' !important;
  font-size: 12px !important;
  padding-bottom: 5px;
  column-gap: 5px;
  color: #4E253A;
  font-weight: bold;
  height: 30px;
  padding-left: 20px;
  width: 100px;
  margin-right: 0px !important;
  padding-top: 5px;
  padding-right: 5px;
}

.filtersDivTextSelected1 {
  font-family: 'GTWalsheimProRegular' !important;
  font-size: 12px !important;
  column-gap: 5px;
  color: #F14C00;
  font-weight: bold;
  background-color: #ffede4;
  padding-left: 20px;
  width: 100px;
  height: 30px;
  margin-right: 0px !important;
  padding-bottom: 5px;
  padding-top: 5px;
  padding-right: 5px;
}

.filterBackdrop {
  position: absolute;
}

.clearFiltersDiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.clearFilterButton {
  background-color: #7C596A;
  color: #ffffff;
  padding: 5px;
  font-size: 12px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 5px;
}

.clearFilterButton:hover {
  background-color: #4E253A;
}

.circularProgress {
  display: flex;
  justify-content: center;
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 40px;
}

.circularProgress svg {
  margin: 0 auto;
  display: block;
  color: #4E253A;
}

.cancelButWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  padding: 4px;
}

.filterButWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 50%;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 10px;
  padding-right: 10px;
}

.filterButWrapper:hover {
  cursor: pointer;
}

.headingContainer {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #87668B;
  color: #ffffff;
}

.headingContainer1 {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #87668B;
  color: #ffffff;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.headingContainer2 {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #87668B;
  color: #ffffff;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.patientListContainer1 {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.patientListContainer1::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.filterDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: 'GTWalsheimProRegular' !important;
  float: right;
  column-gap: 10px;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding-left: 10px;
  padding-right: 10px;
}

.filterDiv:hover {
  cursor: pointer;
}

.filterToggle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 5px;
}

.filterDivSelected {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: 'GTWalsheimProRegular' !important;
  float: right;
  column-gap: 10px;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #FDEAFF;
}


.filterDivSelected1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: 'GTWalsheimProRegular' !important;
  float: right;
  column-gap: 10px;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #ffede4;
}

.filterDivSelected:hover {
  cursor: pointer;
}

.filterDivLabel {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 5px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
}

.noSubjects {
  margin: 60px 20px;
  font-family: GTWalsheimProRegular;
  font-size: 22px;
  font-weight: bold;
  color: #4E253A;
  text-align: center;
}